// |--------------------------------------------------------------------------
// | Transition avec barba et gestion des vues
// |--------------------------------------------------------------------------
// |
// | C'est ici que les transitions entre les pages sont gérées, on y retrouve
// | les différentes vues spécifiques à chaque page. Les vues sont également
// | définies ici.
// |--------------------------------------------------------------------------

import barba from '@barba/core'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { calendar, alerts, clickToScrollToBlock, dragAndSlide, credit, activeListFilters, filters, titleAnimated } from './functions/functions.js'
import { slickHomeBanner, slickProgrammation, slickLogos } from './functions/sliders.js'
import { formatContent } from './functions/content.js'
import { overlayDropdown, overlaySearch, overlayMenu, overlayCalendar, overlayPopup, overlayShare  } from './functions/overlays.js'
import { manageForms, formExample, formContact, formJobs, formSuccess, resetDropzone, formInfolettre } from './functions/form.js'
import { Accordions } from './functions/accordions.js'
import Overlay from '../libraries/overlay.js'

window.barba = barba
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.activeListFilters = activeListFilters

export const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

      // Overlays
      overlayShare()
      overlayDropdown()
      overlaySearch()
      overlayMenu()

      // Crédit
      credit()

      alerts()

      // Formatage de contenu
      formatContent()

      // Formulaires
      manageForms()

      // Back to top
      clickToScrollToBlock({ selector: '.js-scroll-to-header', scrollTo: 'header', offset: 0 })

      formInfolettre('#formNewsletter-popup', '#submitNewsletter-popup')
      formInfolettre('#formNewsletter', '#submitNewsletter')

      SCROLLFIRE.init()
  })

  barba.hooks.after = (() => {
    //gtag('event', 'page_view', { 'send_to' : 'UA-1852550-30', 'page_path': location.pathname })
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search })
    fbq('track', '874351780250112', 'PageView')
  })

  // Dans chaque afterLeave hook
  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    //destroySlick()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })



  barba.init({

    // Options
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions
    transitions: [
      defaultTransition()
    ],

    // Views
    views: [

      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          slickProgrammation()
          overlayPopup()
        },
        afterLeave () {}
      },

      {
        namespace: 'homeView',
        beforeEnter() {
          slickHomeBanner()
          slickProgrammation()
          overlayPopup()
          new Accordions()
          titleAnimated()
          //initHomeBannerHeight()
          //slickSimilarEvents()
          //clickToScrollToBlock({ selector: '.js-scroll-to-links', scrollTo: '.js-links', offset: 0 })
        },
        afterLeave() {}
      },

      {
        namespace: 'actualitesView',
        beforeEnter() {
          filters()
        },
        afterLeave() {}
      },

      {
        namespace: 'actualiteView',
        beforeEnter() {
          slickProgrammation()
          overlayPopup()
        },
        afterLeave() {}
      },

      {
        namespace: 'jobsView',
        beforeEnter() {
          formJobs()
        },
        afterLeave() {}
      },

      {
        namespace: 'jobView',
        beforeEnter() {
          formJobs()
        },
        afterLeave() {}
      },

      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formContact()
          //js-scroll-to-other-coordinateds
          clickToScrollToBlock({ selector: '.js-scroll-to-other-coordinateds', scrollTo: '.js-other-coordinateds', offset: 0 })
        },
        afterLeave() {}
      },

      {
        namespace: 'programmationView',
        beforeEnter() {
          overlayCalendar()
          calendar()
          filters()
        },
        afterLeave() {}
      },

      {
        namespace: 'programmationDetailView',
        beforeEnter() {
          new Accordions()
          slickProgrammation()
          slickLogos()
          clickToScrollToBlock({ selector: '.js-scroll-to-price', scrollTo: '.js-price', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-gallery', scrollTo: '.js-gallery', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-reference', scrollTo: '.js-reference', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-suggestion', scrollTo: '.js-suggestion', offset: 0 })
          dragAndSlide({ name: 'filter' })
          overlayPopup()
        },
        afterLeave() {}
      },

      {
        namespace: 'searchView',
        beforeEnter() {
          filters()
        },
        afterLeave() {}
      },

      {
        namespace: 'error-404View',
        beforeEnter() {},
        afterLeave() {}
      },

      {
        namespace: 'error-500View',
        beforeEnter() {},
        afterLeave() {}
      },
    ]
  })
}


