// |--------------------------------------------------------------------------
// | Content
// |--------------------------------------------------------------------------
// |
// | Les servant à formater le contenu du site
// |--------------------------------------------------------------------------

export function formatContent(){
    wrapYtVideo()
    adjustButtonsMargins()
    addSpanToButtons()
    autoTargetBlank()
    addSvgToLinks()
    manageImageLinks()
    addEndQuoteMarkToBlockquote()
}


// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo(){
    $('.dynamic iframe[src*="youtube.com"]').wrap( '<div class=\'videoWrapper\'><div class=\'ytVideo\'></div></div>' );
}


// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins(){
  $('.dynamic p > a.primary-button, .dynamic p > a.secondary-button').each(function() {
      let paragraph = $(this).parent()
      paragraph.addClass('buttons')
  })
  $('.dynamic p.buttons').each(function() {
      if(!($(this).prev().hasClass('buttons'))){
      $(this).addClass('buttons--first')
      }
      if(!($(this).next().hasClass('buttons'))){
      $(this).addClass('buttons--last')
      }
  })
}
  

// Ajouter un <span> dans les boutons pour l'animation
function addSpanToButtons() {
  let buttons = $('.dynamic .primary-button, .dynamic .secondary-button')
  buttons.wrapInner('<span></span>')
}

  
// Ajouter les icones svg pour les boutons et les liens textes
function addSvgToLinks() {
  let themePath = window.config.theme_path

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    if (!link.querySelector('img')) {      
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-external')
      svgElem.appendChild(useElem);
      link.appendChild(svgElem)
    }
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-download')
      svgElem.appendChild(useElem);
      link.appendChild(svgElem)
    }
  })

}
  

// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {

  let links = document.querySelectorAll('.dynamic a')
  links.forEach(function(link) {
    if (link.querySelector('img')) {      
      link.classList.add('link-image')
    }
  })

}

// Mettre des target _blank automatiquement sur les liens externes
function autoTargetBlank() {
  document.querySelectorAll('.dynamic a').forEach(link => {
    link.hostname !== location.hostname && link.setAttribute('target', '_blank');
  })
}


// Ajouter une classe au dernier élément d'une citation, sauf sur l'auteur
function addEndQuoteMarkToBlockquote() {
  //todo problème quand il y a pas de child et que il y a un slm un child a à l'intérieur
  // Solution entouré tout du span sauf le pre qu'on laisse à l'extérieur du span
  var blockquotes = document.querySelectorAll('.dynamic blockquote')

  if(blockquotes.length > 0) {

    // all blockquotes
    for(var a = 0; a < blockquotes.length; a++){
      var childrensToBlockquote = blockquotes[a].children
      var countChildrensToBlockquote = childrensToBlockquote.length

      // if(!countChildrensToBlockquote){
      //   var newBlockquote_html = '<span>' + blockquotes[a].innerHTML + '</span>'
      //   blockquotes[a].innerHTML = newBlockquote_html
      //   countChildrensToBlockquote = childrensToBlockquote.length
      // }

      // all blockquotes children
      for (var i = (countChildrensToBlockquote - 1); i >= 0; i--) {
        if (childrensToBlockquote[i].tagName !== 'PRE') {
          childrensToBlockquote[i].classList.add('end-quote')
          break
        }
      }
    }
  }
}