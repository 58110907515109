// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------

export const slickHomeBanner = () => {

  $(document).find('.js-home-banner-texts').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    fade: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    asNavFor: '.js-home-banner-images'
  })

  $(document).find('.js-home-banner-images').slick({
    dots: false,
    arrows: true,
    appendArrows: '.js-home-banner-arrow',
    prevArrow: '',
    nextArrow: '<div class="home-banner__arrow"><svg class="home-banner__arrow-svg" role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    slidesToShow: 1,
    fade: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    asNavFor: '.js-home-banner-texts',
    variableWidth: true,
  })

  slickSlideCount('.js-home-banner-texts', '.js-home-banner-pager')
  fixSlickAnim('.js-home-banner-images')

  $( ".home-banner__text-title" ).each(function() {
    let letters_count =  $(this).text().length
    if(letters_count >= 30){
      $(this).addClass('home-banner__text-title--smaller')
    }
  })
}

export const slickProgrammation = () => {
  $(document).find('.js-programmation-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    fade: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    variableWidth: true,
  })
}

// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, leadingZero=true)  {
    $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      let slidePosition = '1';
      if(leadingZero){
        slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
      }else{
        slidePosition = nextSlide + 2 ? (nextSlide + 1) : (nextSlide + 1)
      }
      $(currentElement).html(slidePosition)
    })
  }
  
  
  // Fonction permettant de détruire un carousel Slick
  export function destroySlick(root = document) {
    $(root).find('.slick-initialized').slick('unslick') 
  }
  

  const fixSlickAnim = (slider) => {
    $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      let direction, slideCountZeroBased = slick.slideCount - 1
      let decl = currentSlide - nextSlide
  
      if (nextSlide == currentSlide)
        direction = 'same'
      else if (decl == 1 || decl == (slick.slideCount-1)*(-1))
        direction = 'left'
      else
        direction = 'right'
  
      if (direction == 'right')
        $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')
  
      if (direction == 'left')
        $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
    })
  
    $(slider).on('afterChange', () => {
      $('.slickAnimate', $(slider)).removeClass('slickAnimate')
      $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    })
  }

  export const slickLogos = () => {
    $(document).find('.js-logo-slider').slick({
      dots: false,
      arrows: false,
      slidesToShow: 3,
      fade: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }