// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------

import Overlay from '../../libraries/overlay.js'
import { copyTextToClipboard } from './functions.js'
import { OBSERVER } from '../main.js'
import { Accordions } from './accordions.js'


// Fonction gérant l'overlay menu desktop
export const overlayDropdown = () => {

  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background, .header__logo, .header__top-menu-link', switch: '.js-toggle-overlay-search' }},
    animations: {
      selector: '.js-dropdown-container',
      styles: [{ property: 'height', value: `100vh`, easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800
    }
  })

  dropdown.init()

  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false })
}


// Fonction gérant l'overlay Search
export const overlaySearch = () => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-search' }},
    animations: {
      selector: '.js-overlay-search-content',
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false, // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })
  
  const onOpenComplete = () => { searchInput.focus() }

  search.init()

  const searchInput = document.querySelector('.js-input-to-clear')
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.on('overlaySearch')


  activateWithKeyUp('#form-search #form-search-input')


  $('.js-clear-input').click(function(e) {
    $('.listResult').empty()
    search.animate()
  })

  //Autocomplete search
  function activateWithKeyUp(input, selector) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'#overlay-search .listResult'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false

              if(data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
                search.animate()
              }
              else {
                $('.listResult').empty()
                search.animate()
              }
            },
          })
        }
        else {
          //Remove result from list + resize overlay
          $('.listResult').empty()
          search.animate()
        }
      }
    })
  }
}


// Fonction gérant l'overlay du calendrier
export const overlayCalendar = () => {
  
  let calendar = new Overlay({
    name: 'calendar',
    create: { close: false },
    click: { buttons: { close:'.js-close-overlay,.js-close-overlay-calendar', toggle: '.js-toggle-overlay-calendar', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800
    }
  })
  calendar.init()
  window.calendarOverlay = calendar
}


// Fonction gérant l'overlay menu
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    click: {
      buttons: {
        toggle: '.js-toggle-overlay-menu',
        close: '.js-close-overlay-menu, .js-close-all-overlay',
        switch: '.js-toggle-overlay-search'
      }
    },
    options: { 
      speed: 800,
      closeOnResize: false,
    }
  })

  menu.init()

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu-level-1',
    accordionClass: 'js-accordion-menu-level-1',
    toggleButtonClass: 'js-accordion-menu-toggle-level-1',
    contentClass: 'js-accordion-menu-content-level-1',
    openedClass: 'js-accordion-menu-opened-level-1',
  })

  const level2 = new Accordions({
    containerClass: 'js-accordions-menu-level-2',
    accordionClass: 'js-accordion-menu-level-2',
    toggleButtonClass: 'js-accordion-menu-toggle-level-2',
    contentClass: 'js-accordion-menu-content-level-2',
    openedClass: 'js-accordion-menu-opened-level-2',
  })

  const onClose = () => {
    level1.closeAll()
    level2.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.on('overlayMenu')
}

// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {
    let siteName = window.config.site_name

    if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
      __sharethis__.init(__sharethis__.config)
    }
  
    let share = new Overlay({
      name: 'share',
      click: {
        buttons: {
          open: '.js-share',
          close: '.close-overlay',
        }
      },
      options: {
        speed: 800,
        root: root
      }
    })
  
    share.init()
  
    $('.js-copy-share').click( function() {
      var link = $(this).attr('data-url')
      copyTextToClipboard(link)
      copyTextToClipboard(window.location.href)
      alert('Un url a été copié sur votre presse-papiers.')
      return false
    })
  
    $('.js-share').on('click', function(e) {
      if(e.currentTarget.dataset.rubric){
        window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
      }
      //pour le partage par courriel
      $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
      //pour le partage par facebook ou twitter
      $('#facebookShareBtn').attr('data-url', window.location.href)
      $('#twitterShareBtn').attr('data-url', window.location.href)
    })
  
    //clean l'url quand on ferme l'overlay de share
    $('.overlay-share__background, .overlay-share__close').on('click', function() {
      window.history.replaceState(null, null, ' ')
      //remettre le partage de la page (sans #)
      $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
      $('#facebookShareBtn').attr('data-url', window.location.href)
      $('#twitterShareBtn').attr('data-url', window.location.href)
    })
}


// Fonction gérant le popup
export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    timeout: {
      delay: 2500,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
      disabledPageScroll: false,
      closeOnResize: false,
    }
  })

  // Initialiser le popup seulement si il y en a un à afficher
  let popupExists = document.querySelector('.js-overlay-popup')
  if(popupExists){
    popup.init()
  }
  
}