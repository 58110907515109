// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------

import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}



export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      complete: function (data) { data.then(function(data){ barba.go(data['url'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Fonction gérant les alertes
export function alerts() {
  //const closeAlertsBanner = e => anime({ targets: document.querySelector('.js-alerts-banner'), height: '0px', easing: 'easeOutQuart', duration: 300 })
  const closeAlertsBanner = e => document.querySelector('.js-alerts-banner').style.height = 0
  OBSERVER.add({ name: 'alerts', event: 'click', function: closeAlertsBanner, target: '.js-alerts-close' })
  OBSERVER.on('alerts')
}


// Fonction permettant de créer un navigation horizontale en slidant
export function dragAndSlide(options = {}) {
  options.nameClass === undefined ? options.nameClass = 'drag-and-slide' : options.nameClass
  options.containerClass === undefined ? options.containerClass = 'js-drag-and-slide' : options.containerClass
  options.contentClass === undefined ? options.contentClass = 'js-drag-and-slide-content' : options.contentClass
  options.prevClass === undefined ? options.prevClass = 'js-drag-and-slide-prev' : options.prevClass
  options.nextClass === undefined ? options.nextClass = 'js-drag-and-slide-next' : options.nextClass

  const doc = document,
    container = doc.querySelector(`.${options.containerClass}`),
    content = doc.querySelector(`.${options.contentClass}`),
    prev = doc.querySelector(`.${options.prevClass}`),
    next = doc.querySelector(`.${options.nextClass}`),
    links = doc.querySelectorAll(`.${options.contentClass} a`),
    linksLength = links.length

  let i,
    startX,
    timeoutPreventDefault,
    scrollLeft,
    navigationIsDown = false,
    direction = 'right',
    isDown = false,
    preventDefault = false

  // Empêcher de drag les liens
  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    container.classList.add('active')
    startX = e.pageX - container.offsetLeft
    scrollLeft = container.scrollLeft
    timeoutPreventDefault = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    container.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeoutPreventDefault)
    isDown = false
    container.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - container.offsetLeft
    const walk = (x - startX) * 2
    container.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  function onResize() {
    const containerWidth = container.clientWidth
    const contentWidth = content.clientWidth
    const prevClass = `${options.name}__prev--is-active`
    const nextClass = `${options.name}__next--is-active`

    if(contentWidth > containerWidth) {
      prev.classList.add(prevClass)
      next.classList.add(nextClass)
    } else {
      prev.classList.remove(prevClass)
      next.classList.remove(nextClass)
    }
    onScroll()
  }

  function onScroll() {
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    const prevClass = `${options.name}__prev--is-visible`
    const nextClass = `${options.name}__next--is-visible`

    if(container.scrollLeft > 0) {
      prev.classList.add(prevClass)
    } else {
      prev.classList.remove(prevClass)
    }
    if(container.scrollLeft < maxScrollLeft) {
      next.classList.add(nextClass)
    } else {
      next.classList.remove(nextClass)
    }
  }

  onResize()
  onScroll()

  function setPosition(newPosition) {
    container.scrollLeft = container.scrollLeft + newPosition
  }

  function horizontaleScroll() {
    if (navigationIsDown) {
      if (direction === 'right')
        setPosition(15)
      else
        setPosition(-15)

      setTimeout(() => {
        requestAnimationFrame(horizontaleScroll)
      }, 1000 / 320)
    }
  }

  function mouseDownNext(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'right'
    horizontaleScroll()
    return false
  }

  function mouseDownPrev(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'left'
    horizontaleScroll()
    return false
  }

  function navigationIsDownFalse() {
    navigationIsDown = false
    return false
  }

  OBSERVER.add({ name: 'dragAndSlide', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'dragAndSlide', event: 'scroll', function: onScroll, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDown, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: mouseleave, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: mouseup, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousemove', function: mousemove, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: next })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.on('dragAndSlide')
}


// Fonction permettant d'ouvrir le crédit ou de la fermer
export function credit() {
  if (document.querySelector('.js-credit-toggle')){
    const creditToggle = document.querySelector('.js-credit-toggle')

    creditToggle.onclick = function(){
      creditToggle.classList.toggle('active')
    }
  }
}

//Add active class to selected filter
export function activeListFilters(filter) {
  $('.filter-v2__item--active', $(filter).parent().parent()).removeClass('filter-v2__item--active')
  $(filter).parent().addClass('filter-v2__item--active')
}


// Fonction permettant de show/hide les filtres dans une page de résultats
export function filters() {
  const toggleFiltersBar = () => {
    if (document.querySelector('.js-filters-bar').offsetHeight !== 0){
      document.querySelector('.js-filters-bar').style.height = 0
      setTimeout(function() { document.querySelector('.js-filters-bar').style.display = 'none' }, 300)
    }
    else {
      document.querySelector('.js-filters-bar').style.display = 'block'
      setTimeout(function() { document.querySelector('.js-filters-bar').style.height = 'var(--filter__height)' })
    }
  }

  OBSERVER.add({ name: 'filters', event: 'click', function: toggleFiltersBar, target: '.js-filters-bar-toggle' })
  OBSERVER.on('filters')
}


// Ajouter un <span> dans les titres pour l'animation et changer des propriétés css pour chaque titre animé
export function titleAnimated() {
  let titlesJQ = $('.js-animate-title')
  titlesJQ.wrapInner('<span></span>')

  changeValueVariable()

  // Resize page
  window.addEventListener('resize', () => {changeValueVariable()})

  function changeValueVariable() {
    var titles = document.querySelectorAll('.js-animate-title')

    for(var i = 0; i < titles.length; i++){
      var fontSize = parseFloat(getStyle(titles[i], 'fontSize').replace('px', ''))
      var height = parseFloat($(titles[i].childNodes[0]).height())

      titles[i].style.setProperty('height', `${height}px`)
      titles[i].childNodes[0].style.setProperty('font-size', `${fontSize}px`)
    }
  }
}

export function getStyle(el, cssprop) {
  if (el.currentStyle) // IE & Opera
    return el.currentStyle[cssprop]
  else if (document.defaultView && document.defaultView.getComputedStyle) // Gecko & WebKit
    return document.defaultView.getComputedStyle(el, '')[cssprop]
  else // try and get inline style
    return el.style[cssprop] // XXX I have no idea who is using that
}